@import '/src/assets/styles/reset.css';

@font-face {
  font-family: 'MontserratMedium';
  src: url('./assets/fonts/Montserrat-Medium.woff2') format('woff2'),
    url('./assets/fonts/Montserrat-Medium.woff') format('woff'),
    url('./assets/fonts/Montserrat-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'MontserratSemiBold';
  src: url('./assets/fonts/Montserrat-SemiBold.woff2') format('woff2'),
    url('./assets/fonts/Montserrat-SemiBold.woff') format('woff'),
    url('./assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'MontserratBold';
  src: url('./assets/fonts/Montserrat-Bold.woff2') format('woff2'),
    url('./assets/fonts/Montserrat-Bold.woff') format('woff'),
    url('./assets/fonts/Montserrat-Bold.ttf') format('truetype');
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

* {
  font-family: 'MontserratMedium', sans-serif;
  min-width: 0;
  min-height: 0;

  overflow-wrap: break-word;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

*:focus-visible {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: 'MontserratBold', sans-serif;
}

h1 {
  font-size: 3.75rem;
}

h2 {
  font-size: 2.1875rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.875rem;
}

h6 {
  font-size: 0.75rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
